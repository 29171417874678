import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import useResizeObserver from "use-resize-observer";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const Privacy = () => {
  const [numPages, setNumPages] = useState(0);
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  return (
    <div
      ref={ref}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Document
        file={"./FarewellNotesPrivacy.pdf"}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={width || undefined}
            renderAnnotationLayer={false}
          />
        ))}
      </Document>
    </div>
  );
};
export default Privacy;
