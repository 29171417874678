import React from "react";
import { useNavigate } from 'react-router-dom';

function NavBar() {
  const navigate = useNavigate();

  const handleRedirectToHome = () => {
    navigate('/');
  };

  return (
    <header className="App-header">
      <h1>
        <button onClick={handleRedirectToHome} className="farewell-notes">FAREWELL NOTES</button>
      </h1>
    </header>
  );
}

export default NavBar;
