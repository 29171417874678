import React from "react";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const handleRedirectToHome = () => {
    navigate("/");
  };

  return (
    <footer>
      <p>
        <br />
        <br />
        <a href="/privacy">Privacy</a> | <a href="/terms">Terms</a> | &#169;{" "}
        <button onClick={handleRedirectToHome} className="farewell-notes">
          FAREWELL NOTES LLC
        </button>{" "}
        {new Date().getFullYear()}
      </p>
    </footer>
  );
}

export default Footer;
