import { KeyboardDoubleArrowDownOutlined } from "@mui/icons-material";
import React, { useRef } from "react";
import comingsoonapple from "../../img/comingsoonapple.png";
import comingsoongoogle from "../../img/comingsoongoogle.png";
import phone1 from "../../img/phone-1.png";
import phone2 from "../../img/phone-2.png";
import phone3 from "../../img/phone-3.png";
import phone4 from "../../img/phone-4.png";
import phone from "../../img/phone.png";

import { Avatar } from "@mui/material";
import "./index.css";

function Home() {
  const section1Ref = useRef<HTMLElement | null>(null);
  const section2Ref = useRef<HTMLElement | null>(null);
  const section3Ref = useRef<HTMLElement | null>(null);
  const section4Ref = useRef<HTMLElement | null>(null);

  const handleScrollToSection1 = () => {
    if (section1Ref.current) {
      section1Ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrollToSection2 = () => {
    if (section2Ref.current) {
      section2Ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrollToSection3 = () => {
    if (section3Ref.current) {
      section3Ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleScrollToSection4 = () => {
    if (section4Ref.current) {
      section4Ref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="App">
      <section>
        <div className="main">
          <div className="value-prop">
            <h1 className="h1">
              Document your key assets to provide guidance for your loved ones
              after your death.
            </h1>
            <div className="store-section">
              <a className="store-link" href="#">
                <img src={comingsoongoogle} alt="google store" />
              </a>
              <a className="store-link" href="#">
                <img src={comingsoonapple} alt="app store" />
              </a>
            </div>
          </div>
          <div className="value-prop-and-phone-spacer"></div>
          <div className="phone-image">
            <img className="phone" src={phone} alt="FAREWELL NOTES Phone" />
          </div>
        </div>
        <div className="next-section-button">
          <Avatar>
            <KeyboardDoubleArrowDownOutlined
              className="arrow"
              onClick={handleScrollToSection1}
            />
          </Avatar>
        </div>
      </section>
      <section ref={section1Ref}>
        <div className="section-1">
          <div className="value-prop">
            <h1 className="h1">
              Keep track of both your physical and digital assets to ensure a
              smooth transfer to your beneficiaries. This app helps you document
              essential items such as personal information, wills, financial
              accounts, real estate, pet care details, jewelry locations,
              funeral arrangements, and more. It also allows you to send
              personal messages to designated individuals after your passing.
            </h1>
          </div>
          <div className="value-prop-and-phone-spacer"></div>
          <div className="phone-image">
            <img className="phone" src={phone1} alt="Assets" />
          </div>
        </div>
        <div className="next-section-button">
          <Avatar>
            <KeyboardDoubleArrowDownOutlined
              className="arrow"
              onClick={handleScrollToSection2}
            />
          </Avatar>
        </div>
      </section>
      <section ref={section2Ref}>
        <div className="section-2">
          <div className="value-prop">
            <h1 className="h1">
              You can confidently enter sensitive information or upload
              attachments (up to 1GB) directly into the app, as we use
              military-grade end-to-end encryption and encryption at rest to
              ensure maximum protection. Alternatively, you may choose to store
              this information physically at home and use the app to document
              its location, or combine both approaches.
            </h1>
          </div>
          <div className="value-prop-and-phone-spacer"></div>
          <div className="phone-image">
            <img className="phone" src={phone2} alt="Assets" />
          </div>
        </div>
        <div className="next-section-button">
          <Avatar>
            <KeyboardDoubleArrowDownOutlined
              className="arrow"
              onClick={handleScrollToSection3}
            />
          </Avatar>
        </div>
      </section>
      <section ref={section3Ref}>
        <div className="section-3">
          <div className="value-prop">
            <h1 className="h1">
              After entering your asset information, you may select your
              beneficiaries. You have the option to assign both Primary and
              Backup beneficiaries by prioritizing their roles. Rest assured,
              your asset information will remain completely private from them
              until your account is unlocked. You can also update and modify
              both beneficiaries and asset information as needed over time.
            </h1>
          </div>
          <div className="value-prop-and-phone-spacer"></div>
          <div className="phone-image">
            <img className="phone" src={phone3} alt="Assets" />
          </div>
        </div>
        <div className="next-section-button">
          <Avatar>
            <KeyboardDoubleArrowDownOutlined
              className="arrow"
              onClick={handleScrollToSection4}
            />
          </Avatar>
        </div>
      </section>
      <section ref={section4Ref}>
        <div className="section-4">
          <div className="value-prop">
            <h1 className="h1">
              When the time comes, your beneficiaries can request to unlock your
              account. For added security, you and higher-priority beneficiaries
              have the ability to decline the request if it was made in error.
              Once the account is unlocked, your documented assets will be
              accessible to your beneficiaries. If the Primary beneficiaries do
              not take any action, Backup beneficiaries will ultimately gain
              access.
            </h1>
          </div>
          <div className="value-prop-and-phone-spacer"></div>
          <div className="phone-image">
            <img className="phone" src={phone4} alt="Assets" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default Home;
